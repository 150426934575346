import React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";

import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import Quota from "./Quota";
import CreateQuota from "./CreateQuota";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));
function QuotaView(props) {
  const q = props.quota;
  return (
    <span>
      {`${q.platform}: ${Number.parseFloat(q.usage).toPrecision(2)} of ${q.limit} (${q.units})`};{" "}
    </span>
  );
}


function QuotasView(props) {
  if (props.quotas) {
    return props.quotas.map((quota) => {
      return <QuotaView quota={quota} />;
    });
  } else {
    return "";
  }
}



function AcceptRejectForm(props) {
  const classes = useStyles();
  const [resourceRequest, setResourceRequest] = useState({});

  const handleClose = () => {
    props.onClose();
  };

  

  const handleSave = () => {
    props.onChange(resourceRequest, false);
    props.onClose();
  };


 const handlereject = (event, fieldName) =>{
  let updatedResourceRequest = { ...resourceRequest };
  updatedResourceRequest[fieldName] = 'rejected';
  setResourceRequest(updatedResourceRequest);

 }
 ;
 const handleaccept = (event, fieldName) =>{
  let updatedResourceRequest = { ...resourceRequest };
  updatedResourceRequest[fieldName] = 'accepted';
  setResourceRequest(updatedResourceRequest);

 }
 ;
  useEffect(() => {
    console.log(props.resourceRequest);
    if (props.resourceRequest) {
      setResourceRequest(props.resourceRequest);
    } else {
      setResourceRequest({
        title: "",
        abstract: "",
        description: "",
        context: "",
        collab: "",
        owner: "",
        status: "in preparation",
      });
    }
  }, [props.resourceRequest]);
  return(<Dialog
    onClose={handleClose}
    aria-labelledby="resource-request-dialog-title"
    open={props.open}
    maxWidth="lg"
    fullWidth={true}
  >
    <DialogTitle id="resource-request-dialog-title">
      Editing <i>{resourceRequest.title}</i>
    </DialogTitle>
    <DialogContent>
    <form className={classes.root}>
    <DialogContentText> <b>Title:</b> {resourceRequest.title}</DialogContentText>
    <DialogContentText> <b>Summary:</b> {resourceRequest.abstract}</DialogContentText> 
    <DialogContentText><b>Quotas:</b><QuotasView quotas={resourceRequest.quotas} /></DialogContentText>  
    <DialogContentText><b>Status:</b>{resourceRequest.status}</DialogContentText> 
         
       
        <div>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="default"
            className={classes.button}
            endIcon={<SaveIcon />}
          >
            Save changes
          </Button>
         
          <Button
            onClick={(event) => handlereject(event, "status")}
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<SendIcon />}
          >
            reject
          </Button>
          <Button
            onClick={(event) => handleaccept(event, "status")}
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<SendIcon />}
          >
            accept
          </Button>
        </div>
      </form>
    </DialogContent>
  </Dialog>);}

function ResourceRequestForm(props) {
  const classes = useStyles();

  const [resourceRequest, setResourceRequest] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClose = () => {
    props.onClose();
  };
  

  const handleSave = () => {
    props.onChange(resourceRequest, false);
    props.onClose();
  };

 

  
  const handleCloses = () => {
    setDialogOpen(false);
  };

 



  useEffect(() => {
    console.log(props.resourceRequest);
    if (props.resourceRequest) {
      setResourceRequest(props.resourceRequest);
    } else {
      setResourceRequest({
        title: "",
        abstract: "",
        description: "",
        context: "",
        collab: "",
        owner: "",
        status: "in preparation",
      });
    }
  }, [props.resourceRequest]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="resource-request-dialog-title"
      open={props.open}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle id="resource-request-dialog-title">
        Editing <i>{resourceRequest.title}</i>
      </DialogTitle>
      <DialogContent>
      <form className={classes.root}>
      <DialogContentText> <b>Title:</b> {resourceRequest.title}</DialogContentText>
      <DialogContentText> <b>Summary:</b> {resourceRequest.abstract}</DialogContentText> 
      <DialogContentText><b>Quotas:</b><Quota auth={props.auth} collab={props.collab} quotas={props.quotas} /></DialogContentText>   
      <CreateQuota
        collab={props.collab}
        open={dialogOpen}
        onClose={handleCloses}
        
      /> 
  
    
         
          <div>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              color="default"
              className={classes.button}
              endIcon={<SaveIcon />}
            >
              Save changes
            </Button>
            
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function ResourceRequestDialog(props) {
  
  const { onClose, resourceRequest, open, onChange } = props;

  
  const handleClose = () => {
    onClose();
  };

  if (resourceRequest === null || resourceRequest.status === "accepted") {
    return (
      <ResourceRequestForm
      auth={props.auth}
      collab={props.collab}
        onClose={onClose}
        resourceRequest={resourceRequest}
        open={open}
        onChange={onChange}
      />
    );
  } else if (resourceRequest === null || resourceRequest.status === "under review") {
    return (
      <AcceptRejectForm
      auth={props.auth}
        collab={props.collab}
        onClose={onClose}
        resourceRequest={resourceRequest}
        open={open}
        onChange={onChange}
      />
    );
  }
  
  
  
  
  else {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="resource-request-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="resource-request-dialog-title">{resourceRequest.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{resourceRequest.abstract}</DialogContentText>
          <DialogContentText>{resourceRequest.description}</DialogContentText>
          <DialogContentText>
            <b>ID:</b> {resourceRequest.context}
            <b> Owner:</b> {resourceRequest.owner}
            <b> {resourceRequest.start_date ? "Start date:" : ""}</b> {resourceRequest.start_date}
            <b> Status:</b> {resourceRequest.status}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default ResourceRequestDialog;

