import React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Upcoming } from "@mui/icons-material";

const hw_options = ["BrainScaleS", "SpiNNaker", "BrainScaleS-2", "Spikey"];
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const units_example = {
  SpiNNaker: {
    example: 'core-hours',
  },
  BrainScaleS: {
    example: 'wafer-hours',
  },
  "BrainScaleS-ESS": {
    example: ' ',
  },
  Spikey: {
    example: 'hours',
  },
  "BrainScaleS-2": {
    example: 'chip-hours',
  },
};

function QuotaForm(props) {
  const classes = useStyles();

  const [quota, setQuota] = useState({});

  const [hwIsSelected, set_hwIsSelected] = React.useState(false);
 
  const [commExample, setCommExample] = React.useState('');
  const [hw, set_hw] = React.useState('');
  const [command, setCommand] = React.useState('');
  const handleClose = () => {
    props.onClose();
  };

  const handleChange = (event, fieldName) => {
    let updatedQuota = { ...quota };
    
    updatedQuota[fieldName] = event.target.value;
    setQuota(updatedQuota);
   
  };
  const handleChangelimit = (event, fieldName) => {
    let updatedQuota = { ...quota };
    if(isNaN(event.target.value)==false && Number.isInteger(event.target.value)==false)
    {
    updatedQuota[fieldName] = event.target.value;
    setQuota(updatedQuota);} 
    else{
      alert("You should provide a Float field");
    }
  };
  const handleChangeusage = (event, fieldName) => {
    let updatedQuota = { ...quota };
   
    updatedQuota[fieldName] = event.target.value;
    setQuota(updatedQuota);
    
  };
  const handleSave = () => {
    props.onChange(quota, false);
    props.onClose();
  };

  const handleSubmit = () => {
    props.onChange(quota, true);
    props.onClose();
  };
  const handleHW =(event, fieldName) => {
    
    set_hwIsSelected(true);
    let updatedQuota = { ...quota };
    updatedQuota[fieldName] = event.target.value;
    setCommExample(units_example[event.target.value].example);
    fieldName= 'usage';
    updatedQuota[fieldName] = '0';
    console.log('commExample');
    console.log(units_example[event.target.value].example);
    fieldName='units';
   
    updatedQuota[fieldName]= units_example[event.target.value].example;
    setQuota(updatedQuota);
    console.log('quota.platform');
    console.log(updatedQuota);
   set_hw(event.target.value)
  };
  const handleCommand=(event, fieldName) => {
    let updatedQuota = { ...quota };
    updatedQuota[fieldName] = commExample;
    setQuota(updatedQuota);
    console.log('commExample');
    console.log(commExample);
    setCommand(event.target.value)
  }
  useEffect(() => {
    if(hwIsSelected) {
    
      setCommExample(units_example[hw].example);
    
    }
  }, [hw]);


  useEffect(() => {
    console.log(props.quota);
    if (props.quota) {
      setQuota(props.quota);
    } else {
      setQuota({
          project:"",
        limit: "",
        platform: "",
        units: "",
        usage: "",
        
        
        
      });
    }
  }, [props.quota]);
 
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="quota-dialog-title"
      open={props.open}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle id="quota-dialog-title">
        ADD Quota <i>{quota.title}</i>
      </DialogTitle>
      <DialogContent>
        <form className={classes.root}>


        <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="hardware-simple-select">Hardware</InputLabel>
        <Select
          labelId="hardware-simple-select"
          id="hardware-simple-select"
          value={quota.platform}
         
          onChange={(event) => handleHW(event, "platform")}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {hw_options.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
        </Select>
        <FormHelperText>Please choose a simulation platform</FormHelperText>
      </FormControl>
      </div>
         
      <TextField
            id="usage"
            label="usage"
             variant="filled"
            
            placeholder={0}
             
        
            value={quota.usage}
          //  onChange={(event) => handleChangeusage(event, "usage")}
        
          />
         
         
          <TextField
           id="units"
           label="units:"
         
           placeholder={commExample}
         
          
           variant="filled"
            value={quota.units}
           // onChange={(event) => handleCommand(event, "units")}
          />
          
         
    
    <TextField
            id="limit"
            label="limit"
            variant="outlined"
           
            fullWidth
            value={quota.limit}
            
            onChange={(event) => handleChangelimit(event, "limit")}
        
          />
         
          <div>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              color="default"
              className={classes.button}
              endIcon={<SaveIcon />}
            >
              Save changes
            </Button>
           
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function QuotaDialog(props) {
  const classes = useStyles();
  const { onClose, quota, open, onChange } = props;

  const handleClose = () => {
    onClose();
  };

  
    return (
      <QuotaForm
        onClose={onClose}
        quota={quota}
        open={open}
        onChange={onChange}
      />
    );
  
}

export default QuotaDialog;
