import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from 'axios';

import DialogTitle from "@material-ui/core/DialogTitle";

import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";




import { useState, useRef, useEffect, useCallback } from 'react'


const ebrainsCollabUrl = "https://validation-v2.brainsimulation.eu/";
const quotaUrl = "https://quotas.hbpneuromorphic.eu/";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const config_example = {
  SpiNNaker: {
    example: `{
"spynnaker_version": "master",
"spinnaker_tools_version": "3.1.0",
"extra_pip_installs": ["elephant"],
"extra_git_repositories": ["https://github.com/SpiNNakerManchester/SpiNNakerGraphFrontEnd"],
"extra_makes": ["SpiNNakerGraphFrontEnd/spinnaker_graph_front_end/examples"],
"extra_python_setups": ["SpiNNakerGraphFrontEnd"]
}`,
  },
  BrainScaleS: {
    example: `{
"WAFER_MODULE": 33,
"HICANN": 297,
"SOFTWARE_VERSION":'nmpm_software/current'
}`,
  },
  "BrainScaleS-ESS": {
    example: ``,
  },
  Spikey: {
    example: ``,
  },
  "BrainScaleS-2": {
    example: ``,
  },
};

const command_example = {
  SpiNNaker: {
    example: `run.py {system} --option1=42`,
  },
  BrainScaleS: {
    example: `run.py {system} --option1=42`,
  },
  "BrainScaleS-ESS": {
    example: ``,
  },
  Spikey: {
    example: ``,
  },
  "BrainScaleS-2": {
    example: ``,
  },
};

const hw_options = ["BrainScaleS", "SpiNNaker", "BrainScaleS-2", "BrainScaleS-ESS", "Spikey"];

  const thetext = ''


export default function CreateJob(props) {
// class CreateJob extends React.Component {

  const classes = useStyles();

  const [hw, set_hw] = React.useState('');
  const [mylimit, setMylimit] = React.useState('');
  const [myusage, setMyusage] = React.useState('');
  const [myunits, setMyunits ]= React.useState('');
  const [quotas, setQuotas ]= React.useState({});
  const [myplatform, setMyplatform] = React.useState(false);
  const [hwlabel, set_hwlabel] = React.useState('');

  const [tab, setTab] = React.useState(0);

  const [code, setCode] = React.useState("# write your code here");

  const [configExample, setConfigExample] = React.useState('');
  const [commExample, setCommExample] = React.useState('');
  const [hardwareConfig, setHardwareConfig] = React.useState('');
  const [command, setCommand] = React.useState('');
  const [git, setGit] = React.useState('');
  const [mymodel, setModel] = React.useState('');
  const [tags, setTags] = React.useState([])
  const [errorMessage, setErrorMessage] = React.useState('');

  const { onClose, resourceRequest, open, onChange } = props;
  useEffect(() => {
    console.log('createquotas');
    console.log(props.quotas);
    if (props.quotas) {
      setQuotas(props.quotas);
    } else {
      setQuotas({
        limit: "",
        project: "",
        usage: "",
        
      });
    }
  }, [props.quotas]);

  


  

    const handleChange = (event, fieldName) => {
      let updatedquota = { ...quotas };
      updatedquota[fieldName] = event.target.value;
      setQuotas(updatedquota);
    };
 

  



 
  const handleClose = () => {
    onClose();
  };

function handleSubmit(){
    const Url = 'https://quotas.hbpneuromorphic.eu/';

    const requestConfig = {
      headers: {
        'Authorization': 'Bearer ' + props.auth.token,
        'Content-type': 'application/json'
      }
    }

    

    axios.post(Url, quotas, requestConfig)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error)
      setErrorMessage('Error submitting a job');
    })
  }

  return (
    <Dialog
    onClose={handleClose}
    aria-labelledby="resource-request-dialog-title"
    open={open}
    maxWidth="lg"
    fullWidth={true}
  > <DialogTitle id="resource-request-dialog-title"/>
   
      <DialogContentText> <b>Summary:</b> </DialogContentText> 
      <DialogContentText><b>Quotas:</b></DialogContentText> 
 </Dialog> 
  );
}