import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";


import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";


import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import EditIcon from '@material-ui/icons/Edit';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import StorageIcon from '@material-ui/icons/Storage';
import ViewListIcon from '@material-ui/icons/Subtitles';

import ResourceRequestDialog from "./ResourceRequestDialog";
import PersonIcon from '@material-ui/icons/Person';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import '../App.css';
const baseUrl = "https://quotas.hbpneuromorphic.eu/";





function fetchResourceRequests(collabId, auth) {
  const config = {
    headers: {
      Authorization: "Bearer " + auth.token,
    },

  };
  
  const resourceRequestListUrl = `${baseUrl}projects/`;
  return axios.get(resourceRequestListUrl, config);
}

function saveResourceRequest(resourceRequest, collabId, auth, submit) {
  const config = {
    headers: {
      Authorization: "Bearer " + auth.token,
    },
  };
  if (submit) {
    resourceRequest.submitted = true;
  }
  if (resourceRequest.context) {
  
    const resourceRequestUpdateUrl = `${baseUrl}${resourceRequest.resource_uri}`;
    axios.put(resourceRequestUpdateUrl, resourceRequest, config);
    
  } 
}

function QuotaView(props) {
  const q = props.quota;
  return (
    <span>
      {`${q.platform}: ${Number.parseFloat(q.usage).toPrecision(2)} of ${q.limit} (${q.units})`};{" "}
    </span>
  );
}

function QuotasView(props) {
  if (props.quotas) {
    return props.quotas.map((quota) => {
      return <QuotaView quota={quota} />;
    });
  } else {
    return "";
  }
}

function ResourceRequestList(props) {
  const [resourceRequests, setResourceRequests] = useState([{}]);
 
  const [collabID, setCollabID] = useState(null);
 
  let k =0;
 
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResourceRequestIndex, setSelectedResourceRequestIndex] = useState(0);
  const [currentlySelected, setCurrentlySelected] = useState(null);

  const  [foundresourceRequests, setFoundresourceRequests]=useState([]);
  const  [foundresourceRequestsdefault, setFoundresourceRequestsdefault]=useState([]);
  
  
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectInput, setSelectInput] = useState('');
  
  useEffect(() => {
    
   
    fetchResourceRequests(props.collab, props.auth)
      .then((response) => {
        setResourceRequests(response.data);
       
        
     
       
      })
      .catch((err) => {
        alert("You are not allowed to view Data");
      });   
     
  }, [selectedResourceRequestIndex]);


  const searchItems = (searchValue) => {
    setSelectInput(searchValue)
    if (searchValue !== '') {
        const filteredData = resourceRequests.filter((item) => {
     
          
        
            return Object.values(item.status).join('').toLowerCase().includes(searchValue.toLowerCase())
        })
        setFilteredResults(filteredData)
      }
      else{
        const underresourceRequests = resourceRequests.filter((item) => {
          
          console.log("under");
        
          console.log(underresourceRequests);
          console.log("under");
        
            return Object.values(item.status).join('').toLowerCase().includes('under review'.toLowerCase())
        }) 
       
          setFilteredResults(underresourceRequests)
      }


      
    
    
}




const filterBySearch =(e) => {
  
  setSearchInput(e);
  

  
  const filteredData = filteredResults.filter((item) => {
        
    return Object.values(item).join('').toLowerCase().includes(e.toLowerCase())

})
const filtreddataprimitive = resourceRequests.filter((item) => {
          
  return Object.values(item).join('').toLowerCase().includes('under review'.toLowerCase())}).filter((item) => {
        
    return Object.values(item).join('').toLowerCase().includes(e.toLowerCase())

})
setFoundresourceRequests(filteredData);
setFoundresourceRequestsdefault(filtreddataprimitive);

}

  console.log(resourceRequests);
  const handleClick = (event, index) => {
    console.log(`Clicked ${filteredResults[index].context}`);
    setSelectedResourceRequestIndex(index);
    setCurrentlySelected(filteredResults[index]);
    setCollabID(filteredResults[index].context);
    setDialogOpen(true);
  };
  const handleClick1 = (event, index) => {
    console.log(`Clicked ${filteredResults[index].context}`);
    setSelectedResourceRequestIndex(index);
    setCurrentlySelected(filteredResults[index]);
    setCollabID(filteredResults[index].context);
    setDialogOpen(true);
  };
  const handleClick2 = (event, index) => {
    console.log(`Clicked ${foundresourceRequests[index].context}`);
    setSelectedResourceRequestIndex(index);
    setCurrentlySelected(foundresourceRequests[index]);
    setCollabID(foundresourceRequests[index].context);
    setDialogOpen(true);
  };
  const handleClickk1 = (event, index) => {
  
    setSelectedResourceRequestIndex(index);
    setCurrentlySelected(resourceRequests.filter((item) => {
          
      return Object.values(item).join('').toLowerCase().includes('under review'.toLowerCase())}).filter((item) => {
          
      return Object.values(item).join('').toLowerCase().includes('under review'.toLowerCase())})[index]);
    setCollabID(resourceRequests.filter((item) => {
          
      return Object.values(item).join('').toLowerCase().includes('under review'.toLowerCase())})[index].context);
    setDialogOpen(true);
  };
  const handleClickk2 = (event, index) => {
  
    setSelectedResourceRequestIndex(index);
    setCurrentlySelected(foundresourceRequestsdefault[index]);
    setCollabID(foundresourceRequestsdefault[index].context);
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

const handleChange = (updatedResourceRequest, submit) => {
  let updatedResourceRequests = [...resourceRequests];
  let localIndex = selectedResourceRequestIndex;
  
    localIndex = resourceRequests.length;
    setSelectedResourceRequestIndex(localIndex);
    updatedResourceRequests.push(updatedResourceRequest);
  
  
    saveResourceRequest(updatedResourceRequest, props.collab, props.auth, submit);
  
};



  return (
    <div   style={{ padding: 20 }}>
      
      
 
 


        <div>
      
      <FormControl sx={{ m: 1, minWidth: 222 }}>
        <InputLabel id="demo-controlled-open-select-label">Filter elements with status:</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
      
          defaultValue={"under review"}
          
          
          onChange={(e) => searchItems(e.target.value)}
        >
          <MenuItem value=" " selected="under review">
            <em>None</em>
          </MenuItem>
          <MenuItem value="accepted" selected="selected">accepted</MenuItem>
          <MenuItem value="in preparation" selected="selected">in preparation</MenuItem>
          <MenuItem value="under review" selected="selected">under review</MenuItem>
          <MenuItem value="rejected" selected="selected">rejected</MenuItem>
        </Select>
      </FormControl>
    </div>
    <input type="text"  icon='search'
                placeholder='Search...'
                onChange={(e) => filterBySearch(e.target.value)} 
                 class="search-box"/>
  
  <br/>
  <br/>
  


        { selectInput.length > 1  ? (
      searchInput.length < 1 ?(
        
        <TableContainer component={Paper}>    
    <Table >
      
        <TableHead>
        <tr><th><ViewListIcon/>Title</th>
                      <th><DoneAllIcon /> Status</th>
                      <th><StorageIcon /> Abstract</th>
                      <th> <PersonIcon /> Owner </th>
                   
                      <th> <TrendingUpIcon /> Quotas </th>
                      
                  </tr>
        </TableHead>
       
           <TableBody> 
          {filteredResults.map((rr, index) => (

            <TableRow key={rr.context} hover onClick={(event) => handleClick1(event, index)}>
              <TableCell component="th" scope="row">
                {rr.title}
              </TableCell>
              <TableCell> {rr.status === 'accepted' ? <Chip avatar={<Avatar style={{backgroundColor:'#008000' , color:'white'}}><CheckCircleOutlineIcon /></Avatar>} label={rr.status}
                          style={{backgroundColor:'#008000', color:'white'}}  /> :rr.status === 'rejected'
                        ? (  <Chip avatar={<Avatar><ErrorOutlineIcon /></Avatar>} label={rr.status}
                          color="secondary" /> ) : rr.status === 'under review'
                          ?(  <Chip avatar={<Avatar style={{backgroundColor:'#FFA500' , color:'white'}}><LoopOutlinedIcon /></Avatar>} label={rr.status}
                          style={{backgroundColor:'#FFA500', color:'white'}} /> ):(  <Chip avatar={<Avatar style={{backgroundColor:'#4682B4' , color:'white'}}><EditIcon/></Avatar>} label={rr.status} 
                             style={{backgroundColor:'#4682B4', color:'white'}}  /> ) }</TableCell>
              
              <TableCell>{rr.abstract}</TableCell>
              <TableCell>{rr.owner}</TableCell>
             
              <TableCell>
                <QuotasView quotas={rr.quotas} />
              </TableCell>
            </TableRow>
             ))}
             </TableBody> 
        
             </Table>   </TableContainer>  
         
         )
         :  (
          <TableContainer component={Paper}>    
          <Table >
            
              <TableHead>
              <tr><th><ViewListIcon/>Title</th>
                            <th><DoneAllIcon /> Status</th>
                            <th><StorageIcon /> Abstract</th>
                            <th> <PersonIcon /> Owner </th>
                         
                            <th> <TrendingUpIcon /> Quotas </th>
                            
                        </tr>
              </TableHead>
             
                 <TableBody> 
                {foundresourceRequests.map((rr, index) => (
      
                  <TableRow key={rr.context} hover onClick={(event) => handleClick2(event, index)}>
                    <TableCell component="th" scope="row">
                      {rr.title}
                    </TableCell>
                    <TableCell> {rr.status === 'accepted' ? <Chip avatar={<Avatar style={{backgroundColor:'#008000' , color:'white'}}><CheckCircleOutlineIcon /></Avatar>} label={rr.status}
                                style={{backgroundColor:'#008000', color:'white'}}  /> :rr.status === 'rejected'
                              ? (  <Chip avatar={<Avatar><ErrorOutlineIcon /></Avatar>} label={rr.status}
                                color="secondary" /> ) : rr.status === 'under review'
                                ?(  <Chip avatar={<Avatar style={{backgroundColor:'#FFA500' , color:'white'}}><LoopOutlinedIcon /></Avatar>} label={rr.status}
                                style={{backgroundColor:'#FFA500', color:'white'}} /> ):(  <Chip avatar={<Avatar style={{backgroundColor:'#4682B4' , color:'white'}}><EditIcon/></Avatar>} label={rr.status} 
                                   style={{backgroundColor:'#4682B4', color:'white'}}  /> ) }</TableCell>
                    
                    <TableCell>{rr.abstract}</TableCell>
                    <TableCell>{rr.owner}</TableCell>
                  
                    <TableCell>
                      <QuotasView quotas={rr.quotas} />
                    </TableCell>
                  </TableRow>
                   ))}
                   </TableBody> 
              
                   </Table>   </TableContainer>  
                )):(  searchInput.length < 1 ?(
        
               
                 
                  <TableContainer component={Paper}>    
                  <Table >
                    
                      <TableHead>
                      <tr><th><ViewListIcon/>Title</th>
                                    <th><DoneAllIcon /> Status</th>
                                    <th><StorageIcon /> Abstract</th>
                                    <th> <PersonIcon /> Owner </th>
                               
                                    <th> <TrendingUpIcon /> Quotas </th>
                                    
                                </tr>
                      </TableHead>
                     
                         <TableBody> 
             
                
                  {resourceRequests.filter((item) => {
          
          return Object.values(item).join('').toLowerCase().includes('under review'.toLowerCase())}).map((rr, index) => (
        
        <TableRow key={rr.context} hover onClick={(event) => handleClickk1(event, index)}>
          <TableCell component="th" scope="row">
            {rr.title}
          </TableCell>
          <TableCell>{rr.status === 'accepted' ? <Chip avatar={<Avatar style={{backgroundColor:'#008000' , color:'white'}}><CheckCircleOutlineIcon /></Avatar>} label={rr.status}
                              style={{backgroundColor:'#008000', color:'white'}}  /> :rr.status === 'rejected'
                            ? (  <Chip avatar={<Avatar><ErrorOutlineIcon /></Avatar>} label={rr.status}
                              color="secondary" /> ) : rr.status === 'under review'
                              ?(  <Chip avatar={<Avatar style={{backgroundColor:'#FFA500' , color:'white'}}><LoopOutlinedIcon /></Avatar>} label={rr.status}
                              style={{backgroundColor:'#FFA500', color:'white'}} /> ):(  <Chip avatar={<Avatar style={{backgroundColor:'#4682B4' , color:'white'}}><EditIcon/></Avatar>} label={rr.status} 
                                 style={{backgroundColor:'#4682B4', color:'white'}}  /> ) }</TableCell>
          <TableCell>{rr.abstract}</TableCell>
          <TableCell>{rr.owner}</TableCell>
        
          <TableCell>
            <QuotasView quotas={rr.quotas} />
          </TableCell>
        </TableRow>  )) } 
                </TableBody> 
                </Table>   </TableContainer>
            
                )
                :  (
                 <TableContainer component={Paper}>    
                 <Table >
                   
                     <TableHead>
                     <tr><th><ViewListIcon/>Title</th>
                                   <th><DoneAllIcon /> Status</th>
                                   <th><StorageIcon /> Abstract</th>
                                   <th> <PersonIcon /> Owner </th>
                              
                                   <th> <TrendingUpIcon /> Quotas </th>
                                   
                               </tr>
                     </TableHead>
                    
                        <TableBody> 
                       {foundresourceRequestsdefault.map((rr, index) => (
             
                         <TableRow key={rr.context} hover onClick={(event) => handleClickk2(event, index)}>
                           <TableCell component="th" scope="row">
                             {rr.title}
                           </TableCell>
                           <TableCell> {rr.status === 'accepted' ? <Chip avatar={<Avatar style={{backgroundColor:'#008000' , color:'white'}}><CheckCircleOutlineIcon /></Avatar>} label={rr.status}
                                       style={{backgroundColor:'#008000', color:'white'}}  /> :rr.status === 'rejected'
                                     ? (  <Chip avatar={<Avatar><ErrorOutlineIcon /></Avatar>} label={rr.status}
                                       color="secondary" /> ) : rr.status === 'under review'
                                       ?(  <Chip avatar={<Avatar style={{backgroundColor:'#FFA500' , color:'white'}}><LoopOutlinedIcon /></Avatar>} label={rr.status}
                                       style={{backgroundColor:'#FFA500', color:'white'}} /> ):(  <Chip avatar={<Avatar style={{backgroundColor:'#4682B4' , color:'white'}}><EditIcon/></Avatar>} label={rr.status} 
                                          style={{backgroundColor:'#4682B4', color:'white'}}  /> ) }</TableCell>
                           
                           <TableCell>{rr.abstract}</TableCell>
                           <TableCell>{rr.owner}</TableCell>
                        
                           <TableCell>
                             <QuotasView quotas={rr.quotas} />
                           </TableCell>
                         </TableRow>
                          ))}
                          </TableBody> 
                     
                          </Table>   </TableContainer>  
                       ))
}



      <ResourceRequestDialog
        auth={props.auth}
        collab={collabID}
        resourceRequest={currentlySelected}
        open={dialogOpen}
        onClose={handleClose}
        onChange={handleChange}
      /> 
    </div>
  );
}

export default ResourceRequestList;
